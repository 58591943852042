@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lora:wght@500;600&family=Poppins&display=swap");
@tailwind base;
@tailwind components;
@tailwind utilities;
@font-face {
  font-family: "CustomLatoBlack";
  src: url("./font/lato-black.ttf") format("truetype"); /* Use 'truetype' instead of 'ttf' */
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif, "Poppins", "Lora";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
  font-family: "Lora", serif;
}
h1 {
  font-family: "Montserrat", sans-serif;
  font-weight: bold;
  font-size: 80px;
  letter-spacing: 1px;
  line-height: 1.2;
}

.sectionBox {
  color: white;
  text-align: center;
  height: 100%;
  padding: 2%;
  overflow: hidden;
}
.sectionBox h4 {
  font-size: 3vw;
}
.main h1 {
  color: #f57124;
  font-size: 3vw;
  line-height: 105%;
}
.box {
  color: white;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  height: 100%;
  flex-shrink: 0;
  padding: 3%;
  overflow: hidden;
}
.box h3 {
  /* font-size: 2.6em;
   */
  font-size: 3vw;
  font-family: Lora;
  font-style: normal;
  font-weight: bold;
}
.box p {
  color: #fff;
  text-align: center;
  /* font-size: 2vw; */
  font-family: "Lato", sans-serif;
  font-size: 26px;
}
.box h1 {
  font-size: 64px;
}
p {
  font-family: "Lato", sans-serif;
  font-size: 1.3rem;
  font-size: medium;
}
.heros {
  font-size: 28px;
}
h3 {
  /* font-family: "Poppins", sans-serif; */
  font-family: "Lato", sans-serif;
}
.container {
  padding-left: 5%;
  padding-right: 5%;
}
.pu {
  padding-top: 3%;
  color: #fff;
}
.bgf {
  background-color: #ffd5bc;
}
.inputBox {
  background-color: #fae1d7;
}
.smallBox {
  background-color: #fffaf8;
  box-shadow: 0px 0px 12px 2px rgba(38, 38, 38, 0.2);
}
.footerColor {
  /* color: #FFFAF8; */
  background-color: #1b0a00;
}
#orangeHeading {
  /* font-family: "Lato", sans-serif;  */
  font-family: "CustomLatoBlack";
  /* font-weight: bold; */
  font-weight: 900;
  color: #f57124;
  line-height: 1.1;
}
@media (max-width: 1370px) {
  h1 {
    font-size: 70px;
  }
  #orangeHeading {
    font-size: 44px;
  }
  .box h1 {
    font-size: 52px;
  }
  .box p {
    font-size: 150%;
  }
}
@media (max-width: 1050px) {
  h1 {
    font-size: 55px;
  }
}
@media (max-width: 1270px) {
  .box {
    padding-bottom: 35px;
  }
}
@media (max-width: 965px) {
  h1 {
    font-size: 5.5vw;
  }
  .heros {
    font-size: 18px;
  }
  #orangeHeading {
    font-size: 26px;
  }
  p {
    line-height: 1;
  }
}
@media (mix-width: 640px) {
  #home1 {
    display: none;
  }
}
@media (max-width: 640px) {
  #home2 {
    display: none;
  }
}
#home1 {
  padding-top: 55px;
}
.placeholder-middle::-webkit-input-placeholder {
  line-height: normal !important;
}
/* input::placeholder {
  transform: translateY(-50%);
} */
/* .backimg {
  margin: auto;
  width: 226px;
  height: 477px;
  position: relative;
  top: 4%;
  left: 1%;
}
.imgtitle {
  margin: auto;
  margin-top: 6px;
  font-weight: bold;
  text-align: center;
  font-family: "Poppins", sans-serif;
}
.imgrow {
  gap: 20px;
  margin: auto;
  justify-content: center;
  position: absolute;
  left: 11.4%;
  right: 9.5%;
  top: 8.2%;
  display: flex;
}
.imgrowres {
  display: flex;
  top: 9.5%;
  gap: 20px;
  justify-content: center;
}
.imgrow img {
  width: 210px;
  height: 429px;
  gap: 15px;
}
.imgrowres img {
  width: 170px;
  height: 365px;
}
.smalldevice {
  display: flex;
  justify-content: center;
}
.smalldevice img {
  width: 250px;
  height: 399px;
}

@media (max-width: 1118px) and (min-width: 580px) {
  .imgrow img {
    display: none;
  }
  .imgrow button {
    display: none;
  }
  .smalldevice {
    display: none;
  }
}

@media (max-width: 580px) {
  .imgrowres img,
  .imgrowres button,
  .imgrow img,
  .imgrow button {
    display: none;
  }
}
@media (max-width: 580px) {
  .smalldevice {
    position: absolute;
    top: 48px;
    right: 23.7%;
    left: 22%;
  }
  .smalldevice img {
    width: 190px;
    height: 404px;
  }
  .backimg img {
    width: 164px;
    height: 278px;
  }
  .right_button,
  .left_button {
    padding: 15px;
  }
  #orangeHeading {
    font-size: 1.8rem;
  }
}
@media (max-width: 560px) {
  .smalldevice {
    position: absolute;
    left: 21%;
  }
}
@media (max-width: 400px) {
  .smalldevice {
    position: absolute;
    left: 20.5%;
  }
}

@media (max-width: 1118px) {
  .imgrowres {
    position: absolute;
    left: 18.1%;
    right: 17.9%;
    top: 8.24%;
  }
  .imgrowres img {
    width: 188px;
    height: 404px;
  }
  .backimg img {
    width: 170px;
    height: 298px;
  }
}
@media (max-width: 990px) {
  .imgrowres {
    right: 18.3%;
  }
  #orangeHeading {
    font-size: 1.7rem;
  }
}
@media (max-width: 750px) {
  .imgrowres {
    right: 19%;
  }
}
@media (max-width: 1484px) {
  .imgrow {
    position: absolute;
    right: 10.8%;
  }
  .imgrow img {
    width: 214px;
    height: 405px;
  }
  .backimg img {
    width: 208px;
    height: 420px;
  }
}
@media (max-width: 1300px) {
  .imgrow {
    position: absolute;
    right: 11%;
  }
} */

/* Default styles */
.responsive-button {
  font-size: 20px;
  padding: 10px 20px;
}
/* .nav-links-container {
  display: flex;
  flex-direction: column;
  gap: 1rem; 
} */

.nav-link {
  color: white; /* Initial color of the link text */
  text-decoration: none;
  transition: color 0.2s, text-decoration 0.2s;
  font-weight: 500;
  font-size: 1.125rem;
}

.nav-link:hover,
.nav-link:focus,
.nav-link:active {
  color: black; /* Color of the link text on hover/click */
  text-decoration: underline;
  text-underline-offset: 8px; /* Adjust the underline offset */
}
